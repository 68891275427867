<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              @click="$router.push({name: 'admin.request-forms.create'})"
            ><i data-feather="plus" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="items"
              :sort-mapping="sortMapping"
              :title="'request forms'"
              :total="itemsTotal"
              :fetch-path="'requestForms/fetch'"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    left
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="$router.push({ name: 'admin.request-forms.view', params: { id: field.id } }) ">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View answers
                      </b-dropdown-item>
                      <b-dropdown-item @click="exportData(field.id, 'people')">
                        <i
                          class="me-50"
                          data-feather="file"
                        /> Export people
                      </b-dropdown-item>
                      <b-dropdown-item @click="exportData(field.id, 'answers')">
                        <i
                          class="me-50"
                          data-feather="file"
                        /> Export answers
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="active = field, $store.dispatch('modals/toggleReminderForms', true)">
                        <i
                          class="me-50"
                          data-feather="bell"
                        />
                        Send reminder
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="$router.push({name: 'admin.request-forms.edit', params: { id: field.id }})">
                        <i
                          class="me-50"
                          data-feather="edit"
                        /> Edit
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
            </CompleteTable>
          </div>
        </div>
        <SendReminderForms :form="active" />
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import SendReminderForms from '../../partials/offcanvas/SendReminderForms.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    SendReminderForms,
  },
  data() {
    return {
      active: {},
      sortedField: 'Name form',
      defaultFields: [
        {
          name: 'Created by',
          checked: true,
          order: 1,
        },
        {
          name: 'Created at',
          checked: true,
          order: 2,
        },
        {
          name: 'Name form',
          checked: true,
          order: 3,
        },
        {
          name: 'Start date',
          checked: true,
          order: 4,
        },
        {
          name: 'End date',
          checked: true,
          order: 5,
        },
        {
          name: 'Style',
          checked: true,
          order: 6,
        },
        {
          name: 'Reminder',
          checked: true,
          order: 7,
        },
        {
          name: 'Active',
          checked: true,
          order: 8,
        },
        {
          name: 'People',
          checked: true,
          order: 9,
        },
      ],
      sortMapping: {
        'Created by': 'created_by.full_name',
        'Created at': 'created_at',
        'Name form': 'title',
        'Start date': 'start_date',
        'End date': 'end_date',
        Style: 'style_table',
        Reminder: 'reminder',
        Active: 'active',
        People: 'audience.users.length',
      },
      title: 'Request forms',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      items: 'requestForms/items',
      itemsTotal: 'requestForms/itemsTotal',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'request-forms')
    await this.$store.dispatch('requestForms/fetch')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'request-forms',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    exportData(form, type) {
      Vue.prototype.$export({
        url: `${Vue.prototype.$groupUrl}/request-forms/${form}/export`,
        filters: { type },
        name: `request_forms_${type}`,
      })
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'requestForms/fetch', url: `${Vue.prototype.$groupUrl}/request-forms/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
